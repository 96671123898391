











































import Vue from 'vue'
import helper from '@/services/helper'

export default Vue.extend({
  name: 'ThresholdForm',

  props: {
    oldThreshold: Number,
    minHeight: String,
    submitting: Boolean
  },

  computed: {
    getNumberArray () {
      return helper.getNumberArray
    }
  },

  data () {
    return {
      threshold: this.oldThreshold,
      required: [
        (v: string) => !!v || 'All fields are required'
      ]
    }
  },

  methods: {
    submit () {
      this.$emit('updateThreshold', this.threshold)
    }
  }
})
