

















































import Vue from 'vue'
import snackbar from '../../../../services/snackbar'

export default Vue.extend({
  name: 'EmailForm',

  props: {
    oldEmail: String,
    submitting: Boolean,
    minHeight: String
  },

  data () {
    return {
      email: '',
      password: ''
    }
  },

  watch: {
    oldEmail () {
      this.email = ''
      this.password = ''
    }
  },

  methods: {
    submit () {
      this.$emit('updateEmail', {
        email: this.email,
        password: this.password
      })
    }
  }
})
